@primary-color: #f8b200; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: #262626; // heading text color
@text-color: #333333; // major text color
@text-color-secondary: #868686; // secondary text color
@disabled-color: #bfbfbf; // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #e0e0e0; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@input-bg: #ffffff; // input component background color
@layout-header-background: #ffffff; // layout header background color
@layout-sider-background: #ffffff; // layout sider background color
@layout-trigger-background: #ffffff; // layout side menu default trigger background color
@layout-header-padding: 0; // layout header padding
@body-background: #fafafa; //body bg color
@layout-body-background: transparent; //layout body bg color
@menu-item-padding: 0 10px; // layout Menu.Item padding
@menu-item-boundary-margin: 0; // side menu boundary margin
@menu-item-vertical-margin: 0; // side menu margin between items
@table-header-bg: #ffffff;
@table-header-sort-active-bg: #fefce6;
@table-expanded-row-bg: #fefce6;
@layout-sider-background: #4f4f4f;
@typography-title-font-weight: 400;
@font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.ant-popover-inner {
    max-width: 36rem;
}

.ant-upload.ant-upload-drag {
    border-color: @primary-color !important;
}

.filter-select-popup {
    max-height: 19.6875rem;
    overflow-y: scroll;
}

.signup-spinner {
    .ant-spin-text {
        color: #262626;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 0.5rem;
    }

    .ant-spin-dot-item {
        background-color: #262626;
    }
}
