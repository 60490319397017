/* This file will be for any mandatory global CSS rules. To be avoided if possible */

@keyframes flash {
    0%, 100% {
        border-color: #E0E0E0;
    }
    33% {
        border-color: #F8B200;
    }
    66% {
        border-color: #E0E0E0;
    }
}

body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#zohohc-asap-web-launcherbox {
    display: none;
}

.ant-notification-notice {
    background-color: #f8b200;
    color: #806000;
}

.ant-notification-notice .ant-notification-notice-icon svg {
    fill: #806000;
}

.ant-notification-notice .ant-notification-notice-message {
    color: #806000;
}

.ant-notification-notice .anticon-close svg {
    fill: #806000;
}

.onboarding-modal {
    background-color: #fafafa;
}

body .rcw-conversation-container .rcw-header {
    background-color: #F8B200;
}

body .rcw-client .rcw-message-text {
    background-color: #1890ff;
    padding: 10px 15px;
}

body .rcw-conversation-container .rcw-title {
    color: #fff;
    padding-bottom: 5px;
}

body .rcw-launcher {
    background-color: #F8B200;
    position: fixed;
    bottom: 20px;
    right: 20px;
}

body .rcw-widget-container  {
    margin-bottom: 90px;
}

body .rcw-message-text p {
    color: #fff;
}

body .rcw-response .rcw-message-text, body .loader-container {
    background-color: #fff;
    border: 2px solid #F8B200;
    padding: 10px 15px;
}

body .rcw-message-text {
    box-shadow: 2px 2px 8px 1px #b5b5b5;
}

body .rcw-response p {
    color: rgb(51, 51, 51);
}

body .rcw-picker-btn {
    display: none;
}

.rcw-send {
    position: relative;
    top: -8px;
}

.rcw-launcher {
    background-image: url('https://app.versational.ai/images/woman.png');
    background-size: cover;
    background-position: center;
}

.rcw-launcher:before {
    content: '';
    background-color: rgba(248, 178, 0, 0);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: background-color 0.5s ease;
}

.rcw-launcher img {
    display: none;
}

.rcw-launcher.rcw-hide-sm img {
    display: inline-block;
}

.rcw-launcher.rcw-hide-sm:before {
    background-color: rgba(248, 178, 0, 0.5);
}

body .quick-button {
    border-color: #F8B200;
}

body .quick-button:active {
    background-color: #F8B200;
}

.assign-speakers-mode {
    animation: flash 1s linear infinite;
}

.ant-notification-bottomRight {
    right: 80px !important;
}

.ant-notification-bottomRight .ant-notification-notice {
    position: relative;
    overflow: visible;
}

.ant-notification-bottomRight .ant-notification-notice::after {
    content: '';
    position: absolute;
    right: -14px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-top: 15px solid #F8B200;
    transform: rotate(180deg);
}

.draggable-modal {
    position: relative;
    height: auto;
    top: auto;
    bottom: auto;
}

.draggable-modal .ant-modal {
    max-width: 700px;
    width: 100% !important;
}

.ant-table-cell.text-center {
    text-align: center;
}

.quick-buttons {
	display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}

.ql-editor h2 {
    margin: 0.5rem 0 !important;
    font-size: 1.55rem !important;
}

.ql-editor h3 {
    margin: 0.5rem 0 !important;
    font-size: 1.15rem !important;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #F8B200 !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #F8B200 !important;
}

.ql-editor li, .ql-editor p {
    font-size: 14px !important;
}

.teams-popup {
    .ant-layout-sider {
        display: none;
    }

    .bottom-bar {
        display: none;
    }

    .ant-breadcrumb {
        display: none;
    }
}

.authorization-modal {
    max-width: 460px;
}

.authorization-modal section {
    box-shadow: none;
    max-width: 100%;
}

.authorization-modal .ant-modal-body {
    padding: 0;
}